<template>
  <div>
    <SliderIndexMobile v-if="videoshow" ref="slider" :stopplay="stopplay" />
    <div v-if="!videoshow">
    <div class="video-btn" @click="showvideo()">
      <div class="icon"></div>
      <div class="text">大楼导航</div>
    </div>
      <div class="video-btn2" v-if="false">
        <a href="https://virtual2003.ciif-expo.com/blind-box/index.html#/" target="_blank"></a>
      </div>
    <div
      v-if="tipShow"
      class="tip_bg"
    ></div>
    <div
      id="bg"
      ref="vantaRef"
      class="bg"
    >
    </div>
    <div
      class="index-mobile-bg"
    ></div>
    <div
      v-if="false"
      class="index-mobile-bg2"
      :style="{transform: `translateY(`+translateY2+`px)`}"
    ></div>
    <div
      v-if="false"
      class="index-mobile-logo"
    ></div>
    <div
      v-if="tipShow"
      class="tip-div"
      @click="tipShow=false"
    >
      <div class="updown"></div>
      <div class="click-view">
        <div class="click"></div>
      </div>
    </div>
    <div class="mengban-up"></div>
    <div class="mengban-down"></div>
    <div
      ref="indexMobileRef"
      v-swipedown="(e)=>vueTouch(2,e)"
      v-swipeup="(e)=>vueTouch(1,e)"
      class="index-mobile"
      @touchend="end"
      @touchmove="move"
      @touchstart="start"
    >
      <div
        id="indexPic"
        class="index-pic"
        :style="{transform: `translateY(`+translateY+`px)`}"
      >
        <div class="index-pic-img">
          <div class="index-pic-img-bg">
          </div>
          <template
            v-for="(res) in options.list"
          >
            <template
              v-for="(item,key) in res.images"
            >
              <div
                :key="key"
                class="item-pic animate__infinite	animate__animated animate__slower"
                :class="item.animate"
                :style="{zIndex: item.zIndex || 3, '--animate-duration': item.animateDuration || '8s', animation: item.animation, width: item.mobile.width * indexMobileWidth / 1060 + `px`, height: item.mobile.height * indexMobileWidth / 1060 + `px`, top: item.mobile.top * indexMobileWidth / 1060 + `px`, left: item.mobile.left * indexMobileWidth / 1060 + `px`, background: `url(`+item.pic+`)`, backgroundSize: '100% 100%', opacity: item.opacity || 1}"
              >
              </div>
            </template>
          </template>
          <template v-for="(res, index) in this.options.list[this.options.current].link">
            <div
              :key="index"
              class="link-item"
              :style="{width: res.width+`px`, height: res.height+`px`, top: res.top+`px`, left: res.left+`px` }"
            >
              <a
                v-if="res.out"
                :href="res.url"
                target="_blank"
              ><div
                v-if="!tipShow && options.dotShow"
                class="tip_dot"
                :style="{top: res.dot_top + 'px', left: res.dot_left + 'px'}"
              ></div><span
                v-if="!tipShow && false"
              >{{ res.title }}</span></a>
              <a
                v-else
                href="javascript:void(0)"
                @click="navigateTo(res.url)"
              ><div
                v-if="!tipShow && options.dotShow"
                class="tip_dot"
                :style="{top: res.dot_top + 'px', left: res.dot_left + 'px'}"
              ></div><span
                v-if="!tipShow && false"
              >{{ res.title }}</span></a>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="index-menu-right-mobile"
      :class="tipShow ? `tip` : ``"
    >
      <template v-for="(res, index) in options.list">
        <a
          v-if="typeof res.href == 'string'"
          :key="index"
          class="icon"
          :class="(options.current === index ? `on` : ``) + (` icon-`+index)"
          :style="{backgroundImage:'url('+(options.current === index ? res.icon_on : res.icon)+')'}"
          :href="res.href"
          target="_blank"
        >
          <div
            v-if="options.titleShow"
            class="title"
          >
            {{ res.title }}
          </div>
        </a>
        <div
            v-else
          :key="index"
          class="icon"
          :class="(options.current === index ? `on` : ``) + (` icon-`+index)"
          :style="{backgroundImage:'url('+(options.current === index ? res.icon_on : res.icon)+')'}"
          @click="setCurrent(index)"
        >
          <div
            v-if="options.titleShow"
            class="title"
          >
            {{ res.title }}
          </div>
        </div>
      </template>
      <div class="bankuai-tip"></div>
    </div>

    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SliderIndexMobile from "./slider.vue";

export default {
  name: 'IndexMobile',
  components: {SliderIndexMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCurrent: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      y: 0,
      y2: 0,
      videoshow: false,
      tipShow: true,
      maxW: 0,
      maxH: 0,
      touchClientX: 0,
      touchClientY: 0,
      touchClientY2: 0,
      controlDiv: null,
      oL: 0,
      oT: 0,
      indexMobileWidth: 750
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowHeight', 'windowWidth']),
    translateY(){
      return this.y
    },
    translateY2(){
      return this.y2 - 1300
    }
  },
  watch: {
    options: {
      deep: true,
      handler(newVal, oldVal) {
        this.init()
      }
    }
  },
  activated() {
    // this.tipShow = true
  },
  beforeCreate: function () {
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeRouteEnter: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeDestroy: function(){
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
    document.getElementsByTagName('body')[0].className = ''
  },
  beforeRouteLeave: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  mounted() {
    this.controlDiv = document.querySelector('#bg') //控制器容器元素
    this.indexMobileWidth = this.$refs.indexMobileRef.offsetWidth
    //限制最大宽高，不让滑块出去
    this.maxW = this.controlDiv.offsetWidth
    this.maxH = this.controlDiv.offsetHeight
    var localDate = localStorage.getItem('tipDateMobile');
    if(localDate === null || (localDate !== this.timeFormat(null, 'yymmdd'))){
      this.tipShow = true
    }else{
      localStorage.setItem('tipDateMobile', this.timeFormat(null, 'yymmdd'))
      this.tipShow = false
    }
    localStorage.setItem('tipDateMobile', this.timeFormat(null, 'yymmdd'))
    this.init()
  },
  methods: {
    init(){
      // this.vantaEffect = BIRDS({
      //   el: this.$refs.vantaRef,
      //   lightColor: 0xe8dddd,
      //   speed: 1.80,
      //   texturePath: require('../../../assets/images/mobile/noise.png')
      // })

      this.y = (100 - this.options.list[this.options.current].top + ((this.windowHeight) / 2))
      // setTimeout(() => {
      //   this.tipShow = false
      // }, 4000)

      setTimeout(() => {
        this.y2 = this.y
      }, 100)
    },
    vueTouch(v, e){
      if(v===1){
        //上滑
        if(this.options.current < 8){
          this.setCurrent(this.options.current + 1)
        }else{
          this.setCurrent(8)
        }
      }else{
        //下滑
        if(this.options.current > 0){
          this.setCurrent(this.options.current - 1)
        }else{
          this.setCurrent(0)
        }
      }
    },
    getTranslateY(node){
      var regRule = /translate(Y|\dd)?\(\s*(\w+\s*,)?\s*([^,]+)(\s*,[^)]+)?\s*\)/;
      var regRule2 = /matrix\(.*,\s*(\w+)\s*\)/;
      var transform = node.style.transform;
      var reg;
      if(!transform){
        return null;
      }
      reg = regRule.exec(transform);
      if(null === reg){
        reg = regRule2.exec(transform);
        return reg ? reg[1] : null;
      }
      return reg[3];
    },
    start(e){
      var ev = e || window.event;
      var touch = ev.targetTouches[0];
      //初始位置
      this.oL = touch.clientX
      this.oT = touch.clientY;
      this.touchClientX = touch.clientX
      this.touchClientY = touch.clientY
      //阻止浏览器滑动默认行为
      document.addEventListener('touchmove', this.defaultEvent, { passive: false })
    },
    move(e){
      var ev = e || window.event
      var touch = ev.targetTouches[0]
      var oTop = touch.clientY - this.oT
      this.oT = touch.clientY;
      if(this.options.current === 0 && oTop > 0){
        return
      }
      if(this.options.current === 7 && oTop < 0){
        return
      }
      this.y = this.y + (oTop*3)
      setTimeout(() => {
        this.y2 = this.y
      }, 100)
    },
    end(){
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.options.list.map((res, index) => {
      //       var ly = (100 - res.top + (this.windowHeight) / 2);
      //       var ly2 = index > 0 ? (100 - this.options.list[index-1].top + ((this.windowHeight) / 2)) : (100 - this.options.list[0].top + ((this.windowHeight) / 2))
      //       if(this.y <= ly2 && this.y >= ly){
      //         this.setCurrent(index)
      //       }
      //     })
      //     document.removeEventListener("touchmove", this.defaultEvent, { passive: true })
      //   }, 500)
      // })
      document.removeEventListener("touchmove", this.defaultEvent, { passive: true })
    },
    defaultEvent(e){
      e.preventDefault();
    },
    showvideo(){
      console.log('this.$refs', this.$refs)
      this.videoshow=true;
      this.$nextTick(() => {
        this.$refs['slider'].init()
      })
    },
    stopplay(){
      this.videoshow=false;
      document.getElementsByTagName('body')[0].className = 'index-donghua'
    }
  }
}
</script>

<style scoped lang="scss">
.video-btn{
  position: fixed;
  right: 0;
  top: 100px;
  display: flex;
  height: 64px;
  padding: 0 0px 0 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 1px solid rgba(246, 130, 63, 0.5);
  border-right:0;
  justify-content: center;
  align-items: center;
  color: #F6823F;
  z-index: 2147483647;
  cursor: pointer;
  transition-property: width;
  transition-duration: 1s;
  transition-timing-function: linear;
  transition-delay: 2s;
  /* Safari */
  -webkit-transition-property:width;
  -webkit-transition-duration:1s;
  -webkit-transition-timing-function:linear;
  -webkit-transition-delay:2s;

  .text{
    margin-right: 10px;
    font-size: 24px;
  }
  .icon{
    width: 36px;
    height: 36px;
    background: url("../../../assets/images/index_video_icon.png");
    background-size: 100% 100%;
    margin-right: 10px;
  }
  &:hover{
    .text{
      display: block;
    }
  }
}
.video-btn2{
  position: fixed;
  right: 0;
  top: 100px;
  display: flex;
  height: 64px;
  width: 226px;
  background: url("../../../assets/images/manghe.png");
  background-size: 100% 100%;
  z-index: 2147483647;
  a{
    display: block;
    height: 64px;
    width: 226px;
  }
}
.bg{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.index-mobile-logo{
  width: 250px;
  height: 48px;
  background: url('../../../assets/images/mobile/index_index_mobile_logo.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: fixed;
  left: 50%;
  top: 40px;
  z-index: 9;
  transform: translateX(-50%) scale(1.2);
}
.index-mobile-bg{
  background: url("../../../assets/images/mobile/jianzhu_bg1.png");
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.tip_bg{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.7);
  z-index: 3;
}
.index-menu-right-mobile{
  position: absolute;
  right: 2px;
  top: 40%;
  margin-top: -336px;
  z-index: 5;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-radius: 20px;
  &.tip{
    &:after{
      border-radius: 20px;
      position: absolute;
      right: 2px;
      top: 0;
      content: ' ';
      //background: rgba(255,255,255,0.1);
      //border: 2px dashed #FF8000;
      width: 100%;
      height: 100%;
      //animation-name:fadenum;
      //animation-duration: 2.8s;
    }
    .bankuai-tip{
      display: inline-block;
      //animation-name:fadenum;
      //animation-duration: 2.8s;
    }
  }
  .icon-7{
    &:after{
      display: none!important;
    }
  }
  .icon{
    width: 86px;
    height: 86px;
    margin-bottom: 20px;
    position: relative;
    background-position: center;
    background-size: 100%;
    &:last-child{
      background: #f00;
      display: none;
    }
    &:after{
      content: '';
      width: 4px;
      height: 4px;
      background: #F6823F;
      border-radius: 2px;
      position: absolute;
      bottom: -12px;
      left: 50%;
      display: inline-block;
    }
    .title{
      position: absolute;
      width: 200px;
      left: -210px;
      text-align: right!important;
      color: #f76625;
      top: 14px;
      font-weight: bold;
      font-size: 28px;
      display: none;
      -webkit-animation: breathe 4s infinite;
      animation: breathe 4s infinite;
      -webkit-animation-timing-function: easeOutBack;
      animation-timing-function: easeOutBack;
    }
    &.on{
      .title{
        display: inline-block;
      }
    }
  }
  .bankuai-tip{
    display: none;
    position: absolute;
    width: 113px;
    height: 66px;
    background: url("../../../assets/images/mobile/index_tip_bankuai_mobile.png");
    background-size: 100% 100%;
    bottom: -75px;
  }
}
.index-mobile-bg2{
  width: 750px;
  height: 7400px;
  background: url("../../../assets/images/mobile/jianzhuxing.png");
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  transition: transform 1000ms ease 0s;
  -webkit-transition: transform 1000ms ease 0s;
}
.mengban-up{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to bottom, rgba(100, 100, 100, 0.5) 0%, rgba(255, 255, 255, 0));
  z-index: 3;
}
.mengban-down{
  position:fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to top, rgba(100, 100, 100, 0.5), rgba(255, 255, 255, 0));
  z-index: 3;
}
.index-mobile {
  position: absolute;
  left:0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .index-pic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: transform 1000ms ease 0s;
    -webkit-transition: transform 1000ms ease 0s;
    z-index: 3;
    padding-left: 0px;
    position: relative;
    .index-pic-img{
      .index-pic-img-bg{
        width: 750px;
        height: 7309px;
        background: url("../../../assets/images/mobile/pc_22.png");
        background-size: 100% 100%;
        z-index: 2;
        position: relative;
      }
      .item-pic{
        position: absolute;
        --animate-duration: 8s;
      }
      width: 750px;
      height: 7309px;
      background-size: 100% 100%;
      position: relative;
      .link-item{
        position: absolute;
        z-index: 4;
        a{
          display: inline-block;
          width: 100%;
          height: 100%;
          position: relative;
          -webkit-animation: breathe2 1.5s infinite;
          animation: breathe2 1.5s infinite;
          -webkit-animation-timing-function: easeOutBack;
          animation-timing-function: easeOutBack;
          //background: rgba(255,0,0,0.3);
          .tip_dot{
            display: inline-block;
            width: 170px;
            height: 142px;
            position: absolute;
            background: url("../../../assets/images/mobile/shoushi.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
          span{
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: bold;
            margin-left: 10px;
            color: #f76625;
            text-shadow: 0 0 8px #000;
            display: inline-block;
            width: 150px;
            transform: translateX(20px) translateY(-50%);
          }
          //background: #f00;
        }
      }
    }
  }
}
.tip-div{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  //animation-name:fadenum;
  //animation-duration: 2.8s;
  .updown{
    position: absolute;
    left: 50%;
    margin-left: -66px;
    bottom: 150px;
    width: 178px;
    height: 353px;
    background: url("../../../assets/images/mobile/index_tip_updown_mobile.png");
    background-size: 100% 100%;
    //-webkit-animation: breathe 1.3s infinite;
    //animation: breathe 1.3s infinite;
    //-webkit-animation-timing-function: easeOutBack;
    //animation-timing-function: easeOutBack;
  }
  .click-view{
    position: absolute;
    width: 597px;
    height: 520px;
    //background: rgba(255,255,255,0.1);
    border-radius: 10px;
    left: 18px;
    top: 40%;
    margin-top: -139px;
    //transform: translateX(-50%);
    //border: 2px dashed #FF8000;
    .click{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 185px;
      height: 204px;
      background: url("../../../assets/images/mobile/index_tip_click_mobile.png");
      background-size: 100% 100%;
      -webkit-transition: all 600ms ease;
      transition: all 500ms ease;
      margin: 10px;
      //-webkit-animation: breathe 1.3s infinite;
      //animation: breathe 1.3s infinite;
      //-webkit-animation-timing-function: easeOutBack;
      //animation-timing-function: easeOutBack;
      margin-left: -18px;
      margin-top: -106px;
    }
  }
  .bankuai-view{
    position: absolute;
    .bankuai{
      width: 113px;
      height: 66px;
      background: url("../../../assets/images/mobile/index_tip_bankuai_mobile.png");
      background-size: 100% 100%;
    }
  }
}

@keyframes fadenum{
  0%{opacity:1;}
  70%{opacity:1;}
  90%{opacity:0.9;}
  92%{opacity:0.8;}
  93%{opacity:0.7;}
  94%{opacity:0.6;}
  95%{opacity:0.5;}
  96%{opacity:0.4;}
  97%{opacity:0.3;}
  98%{opacity:0.2;}
  99%{opacity:0.1;}
  100%{opacity:0;}
}
@-webkit-keyframes breathe {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: .4;
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: .4;
  }
}
@keyframes breathe {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: .4;
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: .4;
  }
}
@-webkit-keyframes breathe2 {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: .3
  }
  10% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .6;
  }
  20% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .9;
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .8;
  }
  70% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .6;
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .4;
  }
  90% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .2;
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 0;
  }
}
@keyframes breathe2 {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: .3
  }
  10% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .6;
  }
  20% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .9;
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 1;
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .8;
  }
  70% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .6;
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .4;
  }
  90% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: .2;
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 0, 2deg) translate3d(0, 0, 0);
    opacity: 0;
  }
}
</style>
