<template>
  <div class="user-index-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :logout="true"
      :search="false"
    >
      <span class="nav-title">{{ $t('10021') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="user-index-top-mobile">
        <div class="user-info">
          <div>
            <div class="user-name">
              {{ user.name }}
            </div>
            <div class="user-zhiwei">
              {{ user.companyname }}
            </div>
          </div>
          <div
            v-if="user.qrcode"
            class="user-qrcode"
            @click="showQrcodeModal"
          ></div>
          <div
            v-else
            class="btn"
          >
            <a
              :href="`https://online.ciif-expo.cn/RegMethod.html?pcurl=https://virtual2003.ciif-expo.com/login&phone=`+user.mobile"
              target="_blank"
            >{{ $t('24126') }}</a>
          </div>
        </div>
        <div class="user-other">
          <div class="user-tel">
            {{ user.mobile }}
          </div>
          <div class="user-email">
            {{ user.email }}
          </div>
        </div>
      </div>
      <div class="main-box">
        <div class="title">
          {{ $t('24128') }}
        </div>
        <div class="category">
          <icon-text-column-mobile
            color="rgba(255, 255, 255, 0.6000)"
            font-size="22px"
            icon="user_shoucang.png"
            :text="$t('30002')"
            width="52px"
            @click="navigateTo('/user/collect')"
          />
          <icon-text-column-mobile
            color="rgba(255, 255, 255, 0.6000)"
            font-size="22px"
            icon="user_card.png"
            :text="$t('10020')"
            width="52px"
            @click="navigateTo('/user/card')"
          />
          <icon-text-column-mobile
            color="rgba(255, 255, 255, 0.6000)"
            font-size="22px"
            icon="user_richeng.png"
            :text="$t('30003')"
            width="52px"
            @click="navigateTo('/user/schedule')"
          />
          <icon-text-column-mobile
            color="rgba(255, 255, 255, 0.6000)"
            font-size="22px"
            icon="user_gongxu.png"
            :text="$t('30004')"
            width="52px"
            @click="navigateTo('/user/supply')"
          />
        </div>
      </div>
      <div
        class="main-box link"
        @click="navigateTo('/about')"
      >
        <div class="title">
          {{ $t('10010') }}
        </div>
        <div class="go-btn">
          <img src="../../../assets/images/mobile/more.png" />
        </div>
      </div>
    </div>
    <div
      v-if="qrcodeModal"
      class="qrcode-modal"
    >
      <div class="qrcode-view">
        <div class="title">
          工博一码通
        </div>
        <div
          ref="qrCodeUrl"
          class="qrcode"
        >
          <div class="icon-logo">
            <div class="img"></div>
          </div>
        </div>
        <div class="close-btn">
          <btn-close-mobile
            size="68"
            @click="qrcodeModal=false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import IconTextColumnMobile from "../../../components/Mobile/common/IconTextColumn";
import QRCode from 'qrcodejs2'
import BtnCloseMobile from "../../../components/Mobile/common/BtnCloseMobile";

export default {
  name: 'UserIndexMobile',
  components: {BtnCloseMobile, IconTextColumnMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      backurl: '',
      qrcodeModal: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl
    // this.$nextTick(() => {
    //   if(this.user.qrcode){
    //     this.creatQrCode();
    //   }
    // })
  },
  methods: {
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.user.qrcode, // 需要转换为二维码的内容
        width: 480,
        height: 480,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    showQrcodeModal(){
      this.qrcodeModal = true;
      this.$nextTick(() => {
        this.creatQrCode()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-index-mobile {
  .user-index-top-mobile{
    background: url("../../../assets/images/mobile/user_index_mobile_bg_new.png");
    width: 678px;
    height: 360px;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 20px;
    .user-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .user-name{
        height: 40px;
        font-size: 40px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
      }
      .user-zhiwei{
        height: 28px;
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        margin-top: 20px;
      }
      .user-qrcode{
        width: 64px;
        height: 64px;
        background: url("../../../assets/images/mobile/user_index_mobile_qrcode_new.png");
        background-size: 100% 100%;
      }
    }
    .user-other{
      padding: 0 40px;
      position: absolute;
      bottom: 32px;

      .user-tel, .user-email{
        height: 28px;
        font-size: 24px;
        font-weight: 400;
        color: rgba(255,255,255,0.8);
        line-height: 28px;
      }
      .user-tel{
        background: url("../../../assets/images/mobile/user_index_tel.png") no-repeat;
        background-size: 26px 26px;
        padding-left: 40px;
      }
      .user-email{
        margin-top: 12px;
        background: url("../../../assets/images/mobile/user_index_email.png") no-repeat;
        background-size: 26px 26px;
        padding-left: 40px;
      }
    }
  }
  .qrcode-modal{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.6);
    z-index: 11;
    .qrcode-view{
      width:630px;
      height: 720px;
      background: url("../../../assets/images/mobile/user_index_qrcode_bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .title{
        height: 106px;
        line-height: 106px;
        text-align: center!important;
        font-size: 32px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .qrcode{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        padding: 20px;
        width: 480px;
        height: 480px;
        margin: 62px auto 0;
      }
      .close-btn{
        width: 100%;
        height: 68px;
        position: absolute;
        bottom: -120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .user-info{
    border-radius: 12px;
    width: 100%;
    height: 160px;
    padding: 36px 40px;
    position: relative;
    margin-bottom: 20px;
    .btn{
      width: 188px;
      height: 48px;
      background: linear-gradient(223deg, #E0D3CF 0%, #FCF9F7 100%);
      border-radius: 26px 0 0 26px;
      line-height: 48px;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #FF5523;
      position: absolute;
      right: 0;
      top: 32px;
      a{
        color: #FF5523;
      }
    }
  }
  .link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .go-btn{
      width: 40px;
      height: 40px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .category{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 46px;
    margin-bottom: 10px;
    &>div{
      width: 25%;
      text-align: center;
    }
  }
}
</style>
